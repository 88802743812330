@keyframes btnMgnb {
	0% {
		width: 0;
	}
	100% {
		width: 24px;
	}
}

@keyframes btnMgnbH {
	0% {
		height: 0;
	}
	100% {
		height: 24px;
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fadeOutheight {
	0% {
		opacity: 1;
		height: 600px;
	}
	100% {
		opacity: 0;
		height: 0;
	}
}

.below_2 .wr_logo img {
	width: 55px;
	height: auto;
	display: block;
	/* margin-left: 20px; */
	position: absolute;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
}

.wr_nav_above {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 51px;
	box-sizing: border-box;
	transition: height 0.4s, background 0.4s;
	z-index: 100;
	overflow: hidden;
}

header.off .wr_nav_above {
	background: rgba(255, 255, 255, 0.9);
}

.header.on .wr_nav_above {
	background-color: white;
	color: black;
}

.header.scrolled_down .wr_nav_above {
	background-color: white;
	color: black;
}

.wr_sandwitch {
	/* border: 1px solid red; */
	position: absolute;
	left: 12px;
	top: 6px;
	width: 40px;
	height: 40px;
	text-align: center;
}

.wr_brand {
	/* border: 1px solid red; */
	position: fixed;
	left: 50%;
	top: 18px;
	margin-left: -47px;
	z-index: 11;
	text-align: center;
}

.wr_icon_user {
	/* border: 1px solid red; */
	position: absolute;
	right: 12px;
	top: 6px;
	width: 40px;
	height: 40px;
	text-align: center;
}

.wr_nav_below {
}
/**/

* {
	max-height: 1000000px;
	margin: 0;
	padding: 0;
}

header .wr_nav_below {
	display: none;
	position: relative;
	width: 100%;
	padding-top: 51px;
	/* background: #fff; */
	animation: fade both 0.6s;
}

header.on .wr_nav_below {
	display: block;
}

body {
	background-color: #777;
	font-family: 'Actual', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

section {
}

header.on .header_inner {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: auto;
}
header.on {
	position: fixed;
	width: 100%;
	height: 100vh;
	background-color: white;
}

header.off {
	position: fixed;
	width: 100%;
	background-color: transparent;
}

a:hover,
a:active,
a:focus,
a:visited {
	text-decoration: none;
}

header h1 {
	position: fixed;
	left: 50%;
	top: 18px;
	margin-left: -47px;
	z-index: 11;
}

header h1 a {
	display: block;
	width: inherit;
	font-size: 19px;
	color: #fff;
	text-decoration: none;
}

header.off h1 a {
	color: #000;
	line-height: 22px;
	letter-spacing: -0.1px;
}

header.on h1 a {
	color: #000;
	line-height: 22px;
	letter-spacing: -0.1px;
}

.item_nav {
	font-size: 14px;
	font-weight: 500;
	line-height: 40px;
	position: relative;
}

.item_nav a {
	display: block;
	position: relative;
	transition: all 0.4s;
}

.item_nav.over {
	border-bottom: 1px solid #eee;
}

.item_nav.over .dep_m a:before {
	transform: rotate(-180deg);
}

.dep_m a:before {
	position: absolute;
	right: 20px;
	top: 50%;
	width: 12px;
	height: 7px;
	margin-top: -3px;
	background: url('./../../config/images/ico_gnb_arrow.png') center / 100% no-repeat;
	content: '';
	transition: all 0.4s;
}

header.on:before {
	position: absolute;
	left: 0;
	top: 51px;
	width: 100%;
	height: 1px;
	background: rgba(244, 244, 244, 0);
	content: '';
	z-index: 1;
	transition: background 0.4s;
}

header.on:before {
	background: rgba(244, 244, 244, 1);
}
header.off:before {
	background: rgba(244, 244, 244, 0);
}

ul {
	padding: 0;
	list-style-type: none;
}

.item_nav a {
	text-decoration: none;
}

.dep_m > a {
	color: black;
	letter-spacing: -0.1px;
}

.item_nav_level2 > ul > li > a {
	color: #666;
	letter-spacing: -0.5px;
}

.dep_m a {
	position: relative;
	height: 40px;
	padding: 0 20px;
}

.item_nav.over .item_nav_level2 {
	position: relative;
	display: block;
	border-top: 1px solid #eee;
}

.item_nav .item_nav_level2 {
	position: relative;
	display: none;
	animation: fade both 0.6s;
}

.item_nav.over .dep_m a {
	color: #019ee3;
}

.item_nav_level2 > ul {
	padding: 4px 20px 12px 20px;
}

.item_nav_level2 > ul > li > a {
	/*padding: 0 20px;*/
}

.item_nav_level2 ul li ul li a {
	font-size: 13px;
	font-weight: 100;
	padding: 7px 15px;
	line-height: 18px;
	letter-spacing: -0.1px;
	color: #666;
}

.below_2 {
	/* height: 60px; */
	margin-top: 10px;
	position: relative;
	background: #fff;
	border-bottom: 1px solid #eee;
}

.below_2:before {
	/* content: ''; */
	position: absolute;
	height: 8px;
	background-color: rgba(0, 0, 0, 0);
	width: 100%;
	top: -10px;
	left: 0;
}

.below_2 a {
	height: 60px;
	/* padding: 0 20px; */
	color: #000;
	font-size: 14px;
	font-weight: 600;
	/* line-height: 60px; */
	letter-spacing: -0.2px;
	position: relative;
	/* border:  1px solid red; */
	width: 100%;
	/* height: 100%; */
	display: inline-block;
}

.below_2 a:before {
	position: absolute;
	right: 20px;
	top: 50%;
	width: 12px;
	height: 7px;
	margin-top: -3px;
	background: url(./../../config/images/ico_gnb_arrow.png) center / 100% no-repeat;
	content: '';
	transform: rotate(-90deg);
	opacity: 0.5;
	transition: all 0.4s;
	/* border:  1px solid red; */
}

.below_1,
.below_2 {
	background-color: #fff;
}

.header_inner {
	background-color: #f7f7f7;
}
.wr_logo {
	position: relative;
}

/*sandwitch*/
.btn_m_gnb_cl {
	display: none;
	position: absolute;
	left: 12px;
	top: 6px;
	width: 40px;
	height: 40px;
	text-indent: -9999px;
	overflow: hidden;
	transition: none;
	z-index: 25;
}

.btn_m_gnb_cl em {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 24px;
	height: 24px;
	margin: -12px 0 0 -12px;
	transform: rotate(45deg);
	/* border: 1px solid red; */
}

.btn_m_gnb_cl.on {
	display: block;
}

em {
	font-style: normal;
}

.btn_m_gnb_cl.on em:before {
	animation: btnMgnb both 0.4s;
}
.btn_m_gnb_cl em:before {
	position: absolute;
	left: 0;
	top: 50%;
	width: 24px;
	height: 2px;
	margin-top: -1px;
	background: #333;
	content: '';
}

.btn_m_gnb_cl.on em:after {
	animation: btnMgnbH both 0.4s 0.2s;
}
.btn_m_gnb_cl em:after {
	position: absolute;
	left: 50%;
	top: 0;
	width: 2px;
	height: 24px;
	margin-left: -1px;
	background: #333;
	content: '';
}

.btn_m_gnb_op {
	display: none;
	position: absolute;
	left: 12px;
	top: 6px;
	width: 40px;
	height: 40px;
	text-indent: -9999px;
	overflow: hidden;
	transition: none;
	z-index: 25;
}

.btn_m_gnb_op.on {
	display: block;
}

.btn_m_gnb_op em {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 24px;
	height: 16px;
	margin: -9px 0 0 -12px;
}

.btn_m_gnb_op.on:after {
	display: block;
	animation: btnMgnb both 0.4s 0.2s;
}
.btn_m_gnb_op:after {
	display: none;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 24px;
	height: 2px;
	margin: -1px 0 0 -12px;
	background: #333;
	content: '';
	transition: background 0.4s;
}

.btn_m_gnb_op.on em:before {
	animation: btnMgnb both 0.4s;
}
.btn_m_gnb_op em:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 24px;
	height: 2px;
	background: #333;
	content: '';
	transition: background 0.4s;
}

.btn_m_gnb_op.on em:after {
	animation: btnMgnb both 0.4s 0.4s;
}
.btn_m_gnb_op em:after {
	position: absolute;
	left: 0;
	top: 16px;
	width: 24px;
	height: 2px;
	background: #333;
	content: '';
	transition: background 0.4s;
}

header .link_t {
	position: fixed;
	right: 10px;
	top: 5px;
	z-index: 24;
}

header .link_t .list {
	float: left;
	margin-left: 15px;
}

li {
	list-style: none;
}

header.off .link_t .list .list_m.login {
	/*background: url(./../../config/images/ico_m_login_w.svg) center / 20px 24px no-repeat;*/
	background: url(./../../config/images/ico_m_login.svg) center / 20px 24px no-repeat;
}

header.on .link_t .list .list_m.login {
	background: url(./../../config/images/ico_m_login.svg) center / 20px 24px no-repeat;
}

header.off .link_t .list .list_m.logout {
	/*background: url(./../../config/images/ico_m_logout_w.svg) center / 20px 26px no-repeat;*/
	background: url(./../../config/images/ico_m_logout.svg) center / 20px 26px
		no-repeat;
}

header.on .link_t .list .list_m.logout {
	background: url(./../../config/images/ico_m_logout.svg) center / 20px 26px
		no-repeat;
}

header .link_t .list .list_m {
	display: block;
	width: 40px;
	height: 40px;
	text-indent: -9999px;
	overflow: hidden;
}

.hidden {
	display: none;
}

header .link_t .gnb_cart {
	position: fixed;
	right: 45px;
	top: 5px;
}

header .link_t .gnb_cart em {
	opacity: 0;
}

header .link_t .gnb_cart em.loaded1 {
	opacity: 1;
}

header.on .link_t .gnb_cart a {
	background: url(./../../config/images/ico_gnb_cart_on.png) center / 22px 22px
		no-repeat;
}
header .link_t .gnb_cart a {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	background: url(./../../config/images/ico_gnb_cart.png) center / 22px 22px
		no-repeat;
	font-size: 0;
}

header.off .link_t .gnb_cart a {
	background: url(./../../config/images/ico_gnb_cart_on.png) center / 22px 22px
		no-repeat;
}

header.on .link_t .gnb_cart a {
	background: url(./../../config/images/ico_gnb_cart_on.png) center / 22px 22px
		no-repeat;
}

header .link_t .gnb_cart a em {
	display: block;
	position: absolute;
	left: 24px;
	top: 7px;
	height: 24px;
	padding: 0 7px;
	background: #019ee3;
	border-radius: 12px;
	color: #fff;
	font-size: 18px;
	font-weight: normal;
	line-height: 26px;
	text-indent: 0;
	transform: scale(0.5);
	transform-origin: left top;
}

.wr_nav_above:after {
	position: absolute;
	left: 0;
	top: 51px;
	width: 100%;
	height: 1px;
	background: rgba(244, 244, 244, 1);
	content: '';
	z-index: 100000;
	transition: background 0.4s;
}

.item_nav_user a {
	text-transform: uppercase;
}

.spacer_mobile {
	width: 100%;
	height: 51px;
	background-color: rgba(0, 0, 0, 0);
}

header.off.on_top {
	background-color: rgba(0, 0, 0, 0) !important;
}
header.off.on_top .wr_nav_above {
	background: rgba(0, 0, 0, 0) !important;
}
/*introduction page end*/

header.off.on_top .btn_m_gnb_op em:before {
	background: #fff !important;
}

header.off.on_top .btn_m_gnb_op em:after {
	background: #fff !important;
}

header.off.on_top .btn_m_gnb_op:after {
	background: #fff !important ;
}

header.off.on_top h1 a {
	color: #fff !important;
}

header.off.on_top .link_t .gnb_cart a {
	background: url(./../../config/images/ico_gnb_cart.png) center / 22px 22px
		no-repeat !important;
}

header.off.on_top .link_t .list .list_m.logout {
	background: url(./../../config/images/ico_m_logout_w.svg) center / 20px 26px
		no-repeat !important;
}

header.off.on_top .link_t .list .list_m.login {
	background: url(./../../config/images/ico_m_login_w.svg) center / 20px 24px
		no-repeat !important ;
}
