* {
	box-sizing: border-box;
}

header {
	z-index: 1101;
	position: fixed;
	background-color: white;
	width: 100%;
}

nav.desktop1 {
	float: left;
	width: 100%;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.nav_left {
	/* border: 1px solid red; */
}

.nav_left ul {
	/* border: 1px solid red; */
	margin-left: 5px;
}

.nav_left ul li {
	/* border: 1px solid red; */
}

.nav_right {
	/* border: 1px solid red; */
}

.nav_right ul {
	/* border: 1px solid red; */
}

.nav_right ul li {
	/* border: 1px solid red; */
}

.has_submenu1 {
	/* border: 1px solid red; */
}

.submenu1 {
	/* border: 1px solid red; */
}

.has_submenu2 {
	/* border: 1px solid red; */
}

.submenu2 {
	/*/* border: 1px solid red; */
	margin-bottom: 13px;
	margin-left: 16px;
}

ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

nav li a {
	text-decoration: none;
	color: rgb(140, 140, 140);
	display: inline-block;
	width: 100%;
	height: 100%;
	/* margin-left: -16px; */
	/* margin-right: -16px; */
}

nav ul.submenu1 li a:hover {
	text-decoration: none;
	color: #00adef;
}

.nav_left > ul > li:hover span,
.nav_right li:hover span {
	color: #00adef;
}

.nav_right li.li_cart:hover span {
	color: white;
}

.nav_right li.user_item_desktop:hover span {
	/*color: white !important;*/
}

.nav_right li.li_cart .MuiBadge-badge {
	color: white !important;
}

.submenu1 > li:hover {
	color: #00adef;
}

.logout1li {
	cursor: pointer;
}

.li_cart .MuiButtonBase-root {
	line-height: 38px;
}

.li_cart .MuiIconButton-root {
	transform: translateY(-3px);
}

.qcell_right {
	width: 90px;
	margin-right: 28px;
}

@media screen and (min-width: 0) and (max-width: 760px) {
}

@media screen and (min-width: 760px) and (max-width: 9000px) {
	.nav_left {
		float: left;
	}

	.nav_right {
		float: right;
	}

	.nav_left > ul li {
		display: inline-block;
	}

	.submenu1,
	.submenu2 {
		display: none;
	}

	.nav_left > ul > li {
		padding-left: 5px;
		padding-right: 5px;
		margin-right: 30px;
		height: 60px;
		line-height: 57px;
		cursor: pointer;
		font-size: 15px;
		margin-left : 5px;
		color: #000;
		font-weight: 500;
		letter-spacing: -0.5px;
	}

	.nav_right li {
		letter-spacing: -0.5px;
	}

	li.has_submenu1 {
		position: relative;
	}

	ul.submenu1 {
		position: absolute;
		top: 57px;
		left: 0;
		background-color: #fff;
	}

	ul.submenu1 > li {
		height: 46px;
		line-height: 36px;
		border-left: 1px solid #dfdfdf;
		border-right: 1px solid #dfdfdf;
		border-top: 1px solid #dfdfdf;
	}

	/*	ul.submenu1 > li:first-child {
		border-top-width: 1px !important;
	}*/

	ul.submenu1 > li:last-child {
		border-bottom: 1px solid #dfdfdf;
	}

	ul.submenu1 > li.has_submenu2 {
		height: unset;
	}
	ul.submenu1 li a,
	.astyle {
		/* padding: 4px 16px; */
	}

	li.has_submenu1:hover ul.submenu1 {
		display: block;
	}

	ul.submenu1 {
		width: 350px;
	}
	ul.submenu1 li {
		white-space: nowrap;
		display: block;
		padding: 4px 16px;
	}

	ul.submenu2 > li {
		/*height: 31.6px;*/
		line-height: 31.6px;
		margin-right: -16px;
		border-left: 1px solid #dfdfdf !important;
	}

	ul.submenu3 > li {
		line-height: 31.6px;
		margin-right: -16px;
		border-left: 1px solid #dfdfdf !important;
	}

	.logo1 {
		height: unset !important;
		margin-left: 30px;
		margin-right: 40px !important;
	}
	.logo1 img {
		transform: translateY(7px);
	}

	/*	.nav_left > ul > li:hover {
		border-bottom: 3px solid #00adef;
	}*/

	.nav_left > ul > li {
		position: relative;
	}

	.nav_left > ul > li:hover:after {
		width: 100%;
		transition: width 0.08s linear;
	}

	.qpoint1 {
		margin-left: -16px;
		margin-right: -16px;
		/* margin-top: -4px; */
		/* margin-bottom: -4px; */
		padding: 4px 16px;
		font-size: 15px;
		font-weight: 400;
		color: #8c8c8c;
		height: 45.6px;
		line-height: 36px;
		letter-spacing: -0.1px;
	}

	.qpoint1.open1 {
		border-bottom: 1px solid rgba(223, 223, 223, 1);
	}

	.li_shop_desktop {
		margin-top: -5px;
		height: 51px !important;
	}

	.qpoint1.open1 ~ .submenu2 {
		display: block;
		/* border: 1px solid red; */
		margin-top: 15px;
		margin-bottom: 15px;
		margin-left: 9px;
	}

	.nav_left > ul > li:after {
		content: '';
		position: absolute;
		width: 0%;
		height: 3px;
		left: 5px;
		bottom: 0;
		background-color: #00adef;
		transition: width 0.03s linear;
	}

	.nav_left li.logo1:after {
		display: none;
	}

	ul.submenu2 li {
		/* border: none!important; */
	}

	/*nav right*/
	.nav_right > ul > li {
		height: 57px;
		line-height: 57px;
		padding-left: 30px;
		padding-right: 0px;
		margin-left: 10px;
		font-size: 15px;
		font-weight: 500;
		color: #000;
		float: left;
	}

	.nav_right > ul > li:hover,
	.nav_right > ul > li:hover > a {
		color: #00adef;
	}

	.nav_right > ul > li:hover ul.submenu1 li.logout1li span {
		color: #ddd;
	}

	li.logout1li:hover span {
		color: #00adef !important;
	}
}

.has_arrow,
.has_arrow2 {
	position: relative;
}

.has_arrow.open1:before,
.has_arrow2:hover:before {
	transform: rotate(-180deg);
	fill: #00adef;
}

.has_arrow:before {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 12px;
	height: 7px;
	margin-top: -3px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAOCAYAAAA1+Nx+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3RDFFNTFGM0NCNjMxMUU3QjNCNTg4ODQ2MzI3OEUzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3RDFFNTFGNENCNjMxMUU3QjNCNTg4ODQ2MzI3OEUzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjdEMUU1MUYxQ0I2MzExRTdCM0I1ODg4NDYzMjc4RTNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjdEMUU1MUYyQ0I2MzExRTdCM0I1ODg4NDYzMjc4RTNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+NtBKwQAAAUpJREFUeNqklM1Kw1AQhW9vaxAfIQtfIi58AqHYhVIo/ixEFC3F0lYXrtx1o4jiwk2wUhTFn1as+HTVtJ6BExhCTK924CvpmZkzSXqnuSAI2saYQxCCFhib6SIHLsEeOM/7vv+BizmwCObB5xRD8uAGVMEMWLD4eFUF26DDwv+Yd+gRx4sM2Ac9JW6Buz8Okdp79sYhnlUZMARrMk0lN8AjKDiYF1i7rrRneg4thYimD6qoAp6Al2HusaaiNPHYpKexKhHxEbtKK2cM8XinZaV16RHFgk00SWIX3CptFfQTQ+T6DawoTXp2tHnaAD0kVFoJvINZMgDLKh+yZ5T2A6XFmGf5G9SoFWkssaRqr0H9t93JOiXScAC+QCPFWOJq0vbbCUdQGptc/WRcMJe59dZxkeS/6kx9PwVHxnFJXOOYr0vu+MS16UeAAQA200Mh6ddkyQAAAABJRU5ErkJggg==)
		center / 100% no-repeat;
	content: '';
	transition: all 0.4s;
}

.has_arrow2:before {
	position: absolute;
	right: -1px;
	top: 28px;
	width: 12px;
	height: 7px;
	margin-top: -2px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAOCAYAAAA1+Nx+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3RDFFNTFGM0NCNjMxMUU3QjNCNTg4ODQ2MzI3OEUzQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3RDFFNTFGNENCNjMxMUU3QjNCNTg4ODQ2MzI3OEUzQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjdEMUU1MUYxQ0I2MzExRTdCM0I1ODg4NDYzMjc4RTNCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjdEMUU1MUYyQ0I2MzExRTdCM0I1ODg4NDYzMjc4RTNCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+NtBKwQAAAUpJREFUeNqklM1Kw1AQhW9vaxAfIQtfIi58AqHYhVIo/ixEFC3F0lYXrtx1o4jiwk2wUhTFn1as+HTVtJ6BExhCTK924CvpmZkzSXqnuSAI2saYQxCCFhib6SIHLsEeOM/7vv+BizmwCObB5xRD8uAGVMEMWLD4eFUF26DDwv+Yd+gRx4sM2Ac9JW6Buz8Okdp79sYhnlUZMARrMk0lN8AjKDiYF1i7rrRneg4thYimD6qoAp6Al2HusaaiNPHYpKexKhHxEbtKK2cM8XinZaV16RHFgk00SWIX3CptFfQTQ+T6DawoTXp2tHnaAD0kVFoJvINZMgDLKh+yZ5T2A6XFmGf5G9SoFWkssaRqr0H9t93JOiXScAC+QCPFWOJq0vbbCUdQGptc/WRcMJe59dZxkeS/6kx9PwVHxnFJXOOYr0vu+MS16UeAAQA200Mh6ddkyQAAAABJRU5ErkJggg==)
		center / 100% no-repeat;
	content: '';
	transition: all 0.4s;
}

.user_item_desktop {
	cursor: pointer;
	text-transform: uppercase !important;
}

.nav_right > ul > li > a {
	color: #000;
	font-weight: 500;
	padding-left: 5px;
	padding-right: 5px;
}

.qcell_right img {
	transform: translateY(9px);
}

ul.submenu1 > li > a {
	color: #8c8c8c;
	letter-spacing: -0.1px;
	font-weight: 400;
	font-size: 15px;
}

.submenu2 > li > a {
	color: #8c8c8c;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: -0.1px;
}

.li_login {
}

.li_become_qpartner {
}

.nav_right .MuiButton-root:hover {
	background-color: rgba(0, 0, 0, 0);
}

.nav_right .MuiButton-root:hover {
	background-color: rgba(0, 0, 0, 0);
}

li.li_login:hover p {
	color: #000;
}

li.li_become_qpartner:hover p {
	color: #000;
}

.spacer_desktop {
	width: 100%;
	height: 57px;
	background-color: white;
}

.desktop1 li {
	user-select: none;
}

.list.qcells .list_m:after {
	position: absolute;
	left: 0;
	top: 50%;
	width: 65px;
	height: 13px;
	margin-top: -7px;
	/* img: url('../../icons/login1.png') center no-repeat; */
	content: '';
}

a#product_link {
	width: 65px;
	text-indent: -9999px;
	overflow: hidden;
	height: 57px;
	padding: 0 5px;
	color: black;
	font-size: 15px;
	line-height: 57px;
	letter-spacing: -0.5px;
	font-weight: 500;
	position: relative;
	display: block;
	box-sizing: content-box;
}

li.list.qcells {
	float: left;
	padding-left: 30px;
	background: none;
	border: 0;
	padding-right: 30px;
	margin-right: 10px;
}

li.has_submenu1.has_arrow2.user_item_desktop {
	padding-right: 25px;
}

li.li_cart span {
	font-family: Actual;
	font-size: 12px;
	font-weight: 500;
}

li.li_cart {
	position: relative;
	box-sizing: content-box;
	width: 36px;
}

li.li_cart > a {
	position: relative;
}

.wr_cart_item {
	width: 26px;
	height: 57px;
}

.wr_cart_item_inner {
	position: relative;
	width: 100%;
	height: 100%;
}

span.cart_badge {
	color: #fff;
	background-color: #00adef;
	top: 15px;
	right: 5px;
	transform: scale(1) translate(50%, -50%);
	transform-origin: 100% 0%;
	height: 20px;
	display: flex;
	padding: 0 6px;
	z-index: 1;
	position: absolute;
	flex-wrap: wrap;
	font-size: 0.75rem;
	min-width: 20px;
	box-sizing: border-box;
	transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	align-items: center;
	font-family: 'Actual', 'Helvetica', 'Arial', sans-serif;
	font-weight: 500;
	line-height: 1;
	align-content: center;
	border-radius: 10px;
	flex-direction: row;
	justify-content: center;
	opacity: 0;
}

span.cart_badge.loaded1 {
	opacity: 1;
}
