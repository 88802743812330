@media all and (max-width: 839px) {
    .EmptyCart {
        padding: 80px 60px !important;
    }

    .EmptyCart .EmptyCart_title {
        font-size: 32px;
    }

    .EmptyCart .EmptyCart_subtitle {
        font-size: 16px;
        text-align: center;
    }

    .EmptyCart .EmptyCart_outer {
        width: 7rem;
        height: 7rem;
    }

    .EmptyCart .EmptyCart_inner {
        width: 5rem;
        height: 5rem;
    }

    .EmptyCart .EmptyCart_icon {
        width: 50px;
    }
} 

@media all and (max-width: 576px) {
    .EmptyCart {
        padding: 80px 40px !important;
    }

    .EmptyCart .EmptyCart_title {
        font-size: 28px;
    }

    .EmptyCart .EmptyCart_subtitle {
        font-size: 14px;
        text-align: center;
    }

    .EmptyCart .EmptyCart_button {
        min-width: 60px;
        font-size: 14px;
        padding: 0.9rem 1.8rem;
    }

    .EmptyCart .EmptyCart_outer {
        width: 6rem;
        height: 6rem;
    }

    .EmptyCart .EmptyCart_inner {
        width: 4rem;
        height: 4rem;
    }

    .EmptyCart .EmptyCart_icon {
        width: 36px;
    }
}

@media all and (max-width: 370px) {
    .EmptyCart {
        padding: 80px 20px !important;
    }

    .EmptyCart .EmptyCart_title {
        font-size: 24px;
    }

    .EmptyCart .EmptyCart_subtitle {
        font-size: 14px;
        text-align: center;
    }

    .EmptyCart .EmptyCart_button {
        min-width: 60px;
        font-size: 14px;
        padding: 0.8rem 1.6rem;
    }

    .EmptyCart .EmptyCart_outer {
        width: 5rem;
        height: 5rem;
    }

    .EmptyCart .EmptyCart_inner {
        width: 3rem;
        height: 3rem;
    }

    .EmptyCart .EmptyCart_icon {
        width: 28px;
    }
}