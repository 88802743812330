.HomePage {
  position: relative;
  width: 100%;
  height: 100vh;
  margin-top: -57px;
}

@media only screen and (max-width: 600px) {
  .HomePage {
    overflow: hidden;
    overflow-y: scroll;
    margin-top: -51px;
  }
}

.mc_btn_line:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #019ee3;
  content: '';
}

.mc_btn_line:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: #019ee3;
  content: '';
  transition: width 0.6s 0.2s;
}

.mc_btn_line {
  margin-top: 10px;
  display: inline-block;
  position: relative;
}

.mc_btn_line:hover:before {
  width: 0;
  transition: width 0.4s;
}

.mc_btn_line:hover:after {
  width: 100%;
}

.wrap-info-mb-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 25px;
  background: #f4f4f4;
}

.container-wrap-mobile-sso {
  padding: 25px;
}

.ver {
  height: 13px;
  width: 1px;
  background: gray;
}

.btn-sso-mb {
  width: 100%;
  height: 55px;
  background: #019ee3;
  cursor: pointer;
  color: #fff;
  text-align: center;
  border: none;
  transition: all 0.4s;
  font-size: 16px;
  font-family: Actual;
  text-transform: uppercase;
}

.mb-title-login {
  font-size: 28px;
  font-weight: bold;
  font-family: Actual;
  margin-bottom: 35px;
  text-decoration: underline;
}

.wrap-content-mb-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}

.wrap-content-mb-login span {
  font-size: 12px;
  text-align: center;
}

.page-one .wrap-txt-home {
    margin: 8px 0 0;
    color: white;
    /* font-family: DM Sans; */
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
}

.HomePage .page-one {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.HomePage .page-one:before {
  /*background: url("../../config/images/bg_mc_main_6.jpeg") center;*/
  /*background-attachment: fixed;*/
  /*z-index: -1;*/
}

.modalShowDes {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  overflow-y: auto;
  margin-top: 59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapModalDes {
  position: relative;
  /*display: flex;*/
  width: 65%;
  max-width: fit-content;
  padding: 0;
  margin: 0 auto 30px auto;
  z-index: 100;
  margin-top: -29px;
}

.wrapModalDesDashboard {
  position: relative;
  display: flex;
  width: 1000px;
  max-width: 100%;
  padding: 0;
  margin: 30px auto 30px auto;
  z-index: 100;
}

.wrapModalDes img {
  float: left;
}

.wrapChose {
  display: 'flex';
  justify-content: 'flex-end';
  align-items: 'center';
  background: '#333';
}

.wrapChoseDashboard {
  display: flex;
  background: #333;
  align-items: center;
  justify-content: flex-end;
  max-width: 96%;
  margin: 0px 20px 0px 20px;
  margin-top: -31px;
  width: 1100px;
  z-index: 100;
}

.qpclose:before {
  content: '';
  height: 20px;
  border-left: 2px solid #000;
  position: absolute;
  transform: rotate(-45deg);
  left: 9px;
}

.qpclose:after {
  content: '';
  height: 20px;
  border-left: 2px solid #000;
  position: absolute;
  transform: rotate(45deg);
  left: 9px;
}

.qpclose {
  background: transparent;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  z-index: 101;
}

.overlay-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0.7;
  z-index: 99;
}

.HomePage .page h2 span:first-of-type {
  font-weight: 100;
  color: rgba(0, 0, 0, 0.5);
}

.cs-reward span {
  font-family: Actual;
  cursor: pointer;
}

.cs-reward {
  cursor: pointer;
}
.cs-reward:nth-child(5) img {
    padding: 11px;
    height: 84px;
    width: 78px;
}

.cs-reward:nth-child(1) img {
    padding-top: 2px;
    height: 85px;
    width: 78px;
}

.test-text {
  animation: txtAni both 0.5s 0.8s;
}

.sub-crash {
    animation: txtSub both 0.4s 0.85s;
    font-weight: 400;
    margin-top: 21px;
}

@keyframes txtSub {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes txtAni {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.HomePage .red {
  background: url('../../config/images/bg_mc_main_6.jpeg') center top no-repeat;
  background-size: cover;
}

.HomePage .orange {
  background: url('../../config/images/bg_mc_cont05.jpeg') center top no-repeat;
  /*background: url("./img/bg_mc_cont02.44162e2a.jpeg") center top no-repeat;*/
  background-size: cover;
}

.HomePage .yellow {
  background: url('../../config/images/bg_mc_cont02.jpeg') center top no-repeat;
  /*background: url("./img/bg_mc_cont05.5af777de.jpeg") center top no-repeat;*/
  background-size: cover;
}

.HomePage .green {
  background: url('../../config/images/bg_mc_cont06.jpeg') center top no-repeat;
  background-size: cover;
}

.HomePage .page h2 span:last-of-type {
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.ReactSnapScroll > div.Wrapper {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
}

.HomePage .page-one {
  background: url('../../config/images/bg_mc_main_6.jpeg') center top no-repeat;
  background-attachment: fixed;
  z-index: -1;
  background-size: cover;
}

.HomePage .page-PM {
    background: url("../../config/images/bg_mc_main_6 1.jpg") center top no-repeat;
    background-attachment: fixed;
    z-index: -2;
    background-size: cover;
}

.HomePage .page-two {
    background : url("../../config/images/bg_mc_cont05.jpeg") center top no-repeat;
    background-attachment: fixed;
    z-index: -3;
    background-size: cover;
}

.HomePage .page-three {
    background: url("../../config/images/bg_mc_cont02.jpeg") center top no-repeat;
    background-attachment: fixed;
    z-index: -4;
    background-size: cover;
    /* justify-content: center; */
}

.HomePage .page-four {
  background: url('../../config/images/bg_mc_cont06.jpeg') center top no-repeat;
  background-attachment: fixed;
  z-index: -3;
  background-size: cover;
  /* justify-content: center; */
}

.HomePage .page-five {
    background : url("../../config/images/bg_mc_main_6_1.jpg") center top no-repeat;
    background-attachment: fixed;
    z-index: -6;
    background-size: cover;
}

@media screen and (min-width: 1600px) {
  .txt-cs {
    margin-top: 56px;
  }
  /*.modalShowDes {*/
  /*    margin-top: -386px;*/
  /*}*/
}

.register-txt:before {
  position: absolute;
  left: 13px;
  bottom: 13px;
  width: 0;
  height: 1px;
  background: #666;
  content: '';
  transition: width 0.6s;
}

.contact-us-txt:before {
  position: absolute;
  left: 13px;
  bottom: 1px;
  width: 0;
  height: 1px;
  background: #666;
  content: '';
  transition: width 0.6s;
}

.register-txt:hover:before,
.contact-us-txt:hover:before {
  width: 85%;
}

span.register-txt,
span.contact-us-txt {
  display: inline-block;
  position: relative;
  padding: 5px 1px;
  color: #666;
  font-size: 12px;
  line-height: 14px;
}



.HomePage .blue {
  background: #4682b4;
}

.HomePage .purple {
  background: #9932cc;
}

.HomePage .controls,
.HomePage .pagination {
  position: absolute;

  padding: 1vw;

  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
}

.HomePage .controls {
  top: 1vw;
  right: 1vw;
}


.page-PM .txt-cs, .page-two .txt-cs, .page-five .txt-cs  {
    margin-bottom: 0;
    padding-top: 170px;
    font-family: Actual;
    font-weight: 500;
}

 .page-three .txt-cs, .page-four .txt-cs{
    margin-bottom: 0;
    font-family: Actual;
    font-weight: 400;
    padding-top: 14px;
}

.wrap-btn-sso {
  animation: fade both 0.6s 0.8s;
}

.wrap-info-sso {
  animation: sso both 0.6s 1s;
}

@keyframes sso {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.page-PM .wrap-btn-sso,
.page-two .wrap-btn-sso,
.page-three .wrap-btn-sso,
.page-four .wrap-btn-sso,
.page-five .wrap-btn-sso {
    padding: 30px 0px 45px 0px;
}

.page-PM .wrap-info-sso,
.page-two .wrap-info-sso,
.page-three .wrap-info-sso,
.page-four .wrap-info-sso,
.page-five .wrap-info-sso {
    margin-top: 0;
}

.HomePage .controls h3 {
  font-size: 16px;
  margin: 0 0 10px;
}

.HomePage .pagination {
  bottom: 1vw;
  left: 1vw;
}

@media (max-width: 768px) {
  .HomePage .page {
    font-size: 22px;
  }

  .HomePage .controls {
    top: 2vw;
    right: 2vw;
    padding: 2vw;
  }

  .HomePage .controls h3 {
    margin: 0 0 5px;
  }
}

.ReactSnapScroll > div.Wrapper > div.inner,
.ReactSnapScroll > div.Wrapper > div.inner > * {
  z-index: -1;
}

.item-page-one {
  width: 100%;
  background: url('https://qpartner.s3.amazonaws.com/media/1621247796763-bg_mc_visual01m.jpeg');
  background-size: cover;
  background-position: top center;
  height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.item-page-two {
  width: 100%;
  background: url('https://qpartner.s3.us-west-1.amazonaws.com/media/1621247797225-bg_mc_visual06m.jpeg');
  background-size: cover;
  background-position: top center;
  height: 100vh;
  background-repeat: no-repeat;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
}

.item-page-three {
  width: 100%;
  background: url('https://qpartner.s3.us-west-1.amazonaws.com/media/1621247797426-bg_mc_visual07m.jpeg');
  background-size: cover;
  background-position: top center;
  height: 100vh;
  background-repeat: no-repeat;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
}

.page-four-last {
  transition: transform 0.8s;
  transform: translateY(-60px);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.2, 1.33);
}

.item-page-four {
  width: 100%;
  background: url('https://qpartner.s3.us-west-1.amazonaws.com/media/1621247797522-bg_mc_visual08m.jpeg');
  background-size: cover;
  background-position: top center;
  height: 100vh;
  background-repeat: no-repeat;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
}

/*.text-mb-page-one {*/
/*    text-align: center;*/
/*    padding: 35px 10px 35px 10px;*/
/*    margin-top: -100px;*/
/*}*/

/* Override transition. */
/*
.ReactSnapScroll .akasaka-forward-enter {
    transform: translateX(100%);
}
.ReactSnapScroll .akasaka-reverse-enter {
    transform: translateX(-100%);
}
.ReactSnapScroll .akasaka-forward-enter-active,
.ReactSnapScroll .akasaka-reverse-enter-active {
    transition: transform 1000ms ease-in-out;
    transform: translateX(0);
}
.ReactSnapScroll .akasaka-forward-exit,
.ReactSnapScroll .akasaka-reverse-exit {
    transform: translateX(0);
}
.ReactSnapScroll .akasaka-forward-exit-active {
    transition: transform 1000ms ease-in-out;
    transform: translateX(-100%);
}
.ReactSnapScroll .akasaka-reverse-exit-active {
    transition: transform 1000ms ease-in-out;
    transform: translateX(100%);
}
*/
