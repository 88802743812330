
@media only screen and (min-width: 960px) {
    .Footer_links a p {
        font-size: 14px;
    }

    .Footer_icons button {
        padding: 8px;
    }

    .Footer_copyright {
        font-size: 14px;
    }

    .MuiToolbar-gutters {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media only screen and (min-width: 710px) and (max-width: 960px) {
    .Footer_links img {
        margin-right: 10px;
        height: 15px;
    }

    .Footer_links a {
        padding: 0 5px;
    }

    .Footer_links a p {
        font-size: 13px;
    }

    .Footer_icons button {
        padding: 4px;
    }

    .Footer_copyright {
        font-size: 13px;
    }

    .MuiToolbar-gutters {
        padding-left: 8px;
        padding-right: 8px;
    }
}

@media only screen and (max-width: 710px) {
    .Footer_links a {
        padding: 0 11px;
    }

    .Footer_links a p {
        font-size: 13px;
        color: #666666;
        font-weight: 600;
    }

    .Footer_icons button {
        padding: 4px;
    }

    .Footer_copyright {
        font-size: 12px;
    }

    .MuiToolbar-gutters {
        padding-left: 0;
        padding-right: 0;
    }

    .Footer_links .bl {
        width: 1px;
        height: 10px;
        background: #ddd;
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media only screen and (max-width: 460px) {
    .Footer_links a {
        padding: 0 4px;
    }

    .Footer_links a p {
        font-size: 11px !important;
    }

    .Footer_copyright {
        font-size: 11px !important;
    }
}

@media only screen and (max-width: 350px) {
    .Footer_links a {
        padding: 0 4px;
    }

    .Footer_links a p {
        font-size: 10px !important;
    }

    .Footer_copyright {
        font-size: 11px !important;
    }

    .Footer_links .bl {
        width: 1px;
        height: 10px;
        background: #ddd;
        margin-left: 2px;
        margin-right: 2px;
    }
}

